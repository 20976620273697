@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.cls-1 {
  opacity: 1 !important;
}

.css-1goatyj-Input > input:focus, .css-1w00ls-Input > input:focus, .css-6j8wv5-Input > input:focus, .css-1pndypt-Input > input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  background-color: unset !important;
  color: unset !important;
  --tw-ring-color: transparent !important;
  --tw-ring-offset-color: transparent !important;
  --tw-ring-offset-width: 0 !important;
  --tw-ring-offset-shadow: 0 0 #0000 !important;
}